import { createContext, useContext, useState, useEffect } from 'react'
import { BanknotesIcon, DevicePhoneMobileIcon, AtSymbolIcon } from '@heroicons/react/24/outline'
import {isValidIBAN, electronicFormatIBAN} from 'ibantools'
import { fetchBbApi } from '../utils/bullbitcoin.js'

const JurisdictionContext = createContext()

// Configuration for each jurisdiction
const jurisdictions = {
  CR: {
    id: 'CR',
    name: 'Costa Rica',
    icon: '🇨🇷',
    currencies: [
      {
        id: 'CRC',
        name: 'crc', // maps to language key
        icon: '₡',
        order: 1,
      },
      {
        id: 'USD',
        name: 'usd', // maps to language key
        icon: '$',
        order: 2,
      }
    ],
    paymentTypes: {
      buy: [
        {
          id: 'IN_CRC_RDV_SINPE',
          name: 'sinpe', // maps to language key
          icon: DevicePhoneMobileIcon,
        },
        {
          id: 'IN_CRC_RDV_IBAN',
          name: 'iban', // maps to language key
          icon: BanknotesIcon,
          currencyMapping: {
            CRC: 'IN_CRC_RDV_IBAN',
            USD: 'IN_USD_RDV_IBAN'
          }
        }
      ],
      sell: [
        {
          id: 'SINPE_MOVIL',
          name: 'sinpe',
          icon: DevicePhoneMobileIcon,
          validationRules: {
            currency: ['CRC', 'BTC']
          },
          inputPrefix: '+(506)',
          placeholder: '1234-5678',
          valueField: 'phoneNumber',
          validation: async (value, addError, language) => {
            const isValidSinpe = value.replace(/[^0-9]/gi, '').trim().length === 8

            if(!isValidSinpe) {
              addError(language.createrecipient.errors.invalidSinpe)
              return { isValid: false }
            }

            if(value == process.env.REACT_APP_OUR_SINPE_NUMBER.replace('-', '')) {
              addError(language.createrecipient.errors.invalidSinpe)
              return { isValid: false }
            }
        
            const sinpeCheckRes = await fetchBbApi({
                service: "api-recipients",
                method: "checkSinpe",
                params: {
                  phoneNumber: value,
                },
              })
        
              if(sinpeCheckRes.error) {
                if(sinpeCheckRes.data?.data?.errors?.phoneNumber) {
                  addError(language.createrecipient[sinpeCheckRes.data.data.errors.phoneNumber[0].code])
                } else {
                  addError(sinpeCheckRes.message)
                }
        
                return { isValid: false }
              }
              
              return { 
                isValid: true,
                recipientName: sinpeCheckRes.ownerName,
              }
          },
          formatValue: (value) => value.replace(/[^0-9]/gi, '').trim()
        },
        {
          id: 'IBAN_CR',
          name: 'iban',
          icon: BanknotesIcon,
          inputPrefix: 'CR',
          placeholder: '1234567890123',
          valueField: 'iban',
          validation: async (value, addError, language) => {
            const isValidIban = isValidIBAN(electronicFormatIBAN(
              (value.toUpperCase().indexOf("CR") !== 0 ? `CR${value}` : value)
            ))

            if(!isValidIban) {
              addError(language.createrecipient.errors.invalidIban)
              return { isValid: false }
            }

            const ibanCheckRes = await fetchBbApi({
                service: "api-recipients",
                method: "checkIbanCR",
                params: {
                    iban: value,
                },
            })
    
            if(ibanCheckRes.error) {
                if(ibanCheckRes.data?.data?.errors?.iban) {
                    addError(language.createrecipient[ibanCheckRes.data.data.errors.iban[0].code])
                } else {
                    addError(ibanCheckRes.message)
                }
                return { isValid: false }
            }

            return { 
              isValid: true,
              recipientName: ibanCheckRes.ownerName,
              currency: ibanCheckRes.currency,
            }
          },
          formatValue: (value) => value.toUpperCase().indexOf("CR") !== 0 ? `CR${value}` : value
        }
      ]
    },
    depositInfo: {
      sinpeNumber: process.env.REACT_APP_OUR_SINPE_NUMBER,
      companyName: 'Toro Pagos Limitada',
      companyCedula: '3-102-875766',
      dailyLimit: {
        amount: 1500000,
        currency: 'CRC'
      }
    }
  },
  CA: {
    id: 'CA',
    name: 'Canada',
    icon: '🇨🇦',
    currencies: [
      {
        id: 'CAD',
        name: 'cad', // maps to language key
        icon: '$',
        order: 1,
      }
    ],
    paymentTypes: {
      buy: [
        {
          id: 'IN_ETRANSFER',
          name: 'interac', // maps to language key
          icon: AtSymbolIcon,
        }
      ],
      sell: [
        {
          id: 'OUT_INTERAC_EMAIL',
          name: 'interac',
          icon: AtSymbolIcon,
          placeholder: 'email@example.com',
          valueField: 'email',
          validation: (value) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value),
          formatValue: (value) => value
        }
      ]
    },
    depositInfo: {
      interacEmail: 'test@satoshiportal.com',
      dailyLimit: {
        amount: 999,
        currency: 'CAD'
      }
    }
  }
}

export function JurisdictionProvider({ children }) {
  const [jurisdiction, setJurisdiction] = useState(null)

  useEffect(() => {
    const getInitialJurisdiction = () => {
      const params = new URL(document.location).searchParams
      const key = params.get('jurisdiction')

      if (key && jurisdictions[key]) {
        return key
      }

      if (process.env.REACT_APP_DEFAULT_JURISDICTION && 
          jurisdictions[process.env.REACT_APP_DEFAULT_JURISDICTION]) {
        return process.env.REACT_APP_DEFAULT_JURISDICTION
      }

      return null
    }

    setJurisdiction(getInitialJurisdiction())
  }, [])

  const jurisdictionConfig = jurisdiction ? jurisdictions[jurisdiction] : null

  const contextValue = {
    jurisdiction,
    setJurisdiction,
    jurisdictionConfig,
    jurisdictions
  }

  return (
    <JurisdictionContext.Provider value={contextValue}>
      {children}
    </JurisdictionContext.Provider>
  )
}

export function useJurisdiction() {
  const context = useContext(JurisdictionContext)
  if (context === undefined) {
    throw new Error('useJurisdiction must be used within a JurisdictionProvider')
  }
  return context
}